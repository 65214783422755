<template>
    <div class="wrap">
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="100px" class="demo-form-inline">
                    <el-form-item label="月份">
                        <el-date-picker v-model="queryParams.recordMonth" type="month" style="width: 200px" value-format="yyyy-MM" placeholder="选择月份"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="入住机构">
                        <el-select class="w200px" v-model="queryParams.serviceId" clearable placeholder="请选择入住机构">
                            <el-option v-for="item in institutionsListConfig" :key="item.serviceId" :label="item.serviceName" :value="item.serviceId"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="老人类型">
                        <el-select class="w200px" v-model="queryParams.mzeeType" clearable placeholder="请选择老人类型">
                            <el-option v-for="item in mzeeTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核状态">
                        <el-select class="w200px" v-model="queryParams.state" clearable placeholder="请选择审核状态">
                            <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="当前审核单位">
                        <el-select class="w200px" v-model="queryParams.examineGrade" clearable placeholder="请选择当前审核单位">
                            <el-option v-for="(item, index) in examineGradeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="老人新增状态">
                        <el-select class="w200px" v-model="queryParams.newStatus" clearable placeholder="请选择老人新增状态">
                            <el-option v-for="(item, index) in newStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索内容">
                        <el-input v-model="queryParams.keyWord" placeholder="姓名/电话/身份证号" clearable style="width: 200px" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                        <el-button type="primary" class="search" plain @click="resetHandler">重置</el-button>
                    </el-form-item>
                    <div style="float: right">
                        <el-button v-if="!multipleSelection.length" type="primary" class="search" @click="toMutipleHnalder">批量审核</el-button>
                        <el-button v-else type="primary" class="search" @click="tosubmit">选好了</el-button>
                        <el-button type="primary" class="search" @click="oldExporthandler">导出</el-button>
                    </div>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" @selection-change="handleSelectionChange" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column type="selection" :selectable="selected" align="center" width="55"> </el-table-column>
                    <el-table-column type="index" label="序号" align="center" width="60" />
                    <el-table-column prop="serviceName" align="center" label="入住机构" width="180" />
                    <el-table-column prop="userName" align="center" label="姓名" />
                    <el-table-column prop="sex" align="center" label="性别" />
                    <el-table-column prop="age" align="center" label="年龄（岁）" width="120" />
                    <el-table-column prop="cardId" align="center" label="身份证号" width="180" />
                    <el-table-column prop="phone" align="center" label="联系电话" width="150" />
                    <el-table-column prop="mzeeType" align="center" label="老人类型" width="150" />
                    <el-table-column prop="number" align="center" label="床位号" width="150" />
                    <el-table-column prop="liveStartTime" align="center" label="入住时间" width="150" />
                    <el-table-column prop="retreatTime" align="center" label="离院时间" width="150" />
                    <el-table-column prop="newStatus" align="center" label="老人新增状态" width="150" />
                    <el-table-column prop="examineGrade" align="center" label="当前审核单位" width="150" />
                    <el-table-column prop="state" align="center" label="审核状态" width="150" />
                    <el-table-column label="操作" align="center" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.examineState == 0" @click="toReview(scope.row)">审核</el-button>
                            <el-button type="text" v-else @click="seeDetial(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin: 20px 0"
                    background
                    v-if="total > 0"
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :page-size="queryParams.pageSize"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
        <el-dialog title="批量审核" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <div>
                <div style="margin: 0 0 10px 30px">当前选中{{ multipleSelection.length }}条数据</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="审核意见" prop="state">
                        <el-radio-group v-model="ruleForm.state">
                            <el-radio :label="0">通过</el-radio>
                            <el-radio :label="1">不通过</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { registrationList, institutionsList, oldExport, batchAudit } from '@/api/institutionalSubsidies'
import { downloadStream } from '@/utils/index'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                state: 0
            },
            rules: {
                state: [{ required: true, message: '请选择审核意见', trigger: 'change' }]
            },
            multipleSelection: [],
            total: 0,
            loading: false,
            tableData: [],
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                examineGrade: '',
                keyWord: '',
                mzeeType: '',
                newStatus: '',
                recordMonth: '',
                serviceId: '',
                state: ''
            },
            mzeeTypeList: [
                { label: '本区老人', value: 1 },
                { label: '非本区老人', value: 2 }
            ],
            stateList: [
                { label: '未提交', value: 0 },
                { label: '待审核', value: 1 },
                { label: '通过', value: 2 },
                { label: '不通过', value: 3 }
            ],
            examineGradeList: [
                { label: '社区委员会', value: 1 },
                { label: '街道办事处', value: 2 },
                { label: '民政局', value: 3 }
            ],
            newStatusList: [
                { label: '当月新增', value: 1 },
                { label: '非当月新增', value: 2 }
            ],
            institutionsListConfig: []
        }
    },
    mounted() {
        this.getNowTime()
        this.getList()
        this.institutionsListData()
    },
    methods: {
        submitForm() {
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.multipleSelection))
                    let arr = []
                    params.forEach(item => {
                        arr.push({ id: item.id, refusalCause: '', state: this.ruleForm.state })
                    })
                    this.batchAuditPost(arr)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async batchAuditPost(params) {
            const res = await batchAudit(params)
            if (res.state === 0) {
                this.$message.success(res.data)
                this.getList()
            }
            this.dialogVisible = false
        },
        tosubmit() {
            this.dialogVisible = true
        },
        toMutipleHnalder() {
            if (!this.multipleSelection.length) this.$message.warning('请勾选需要审批的数据！')
        },
        /*禁用不可审核的数据*/
        selected(row) {
            if (row.examineState == 1) {
                return false //不可勾选
            } else {
                return true //可勾选
            }
        },
        async oldExporthandler() {
            const res = await oldExport(this.queryParams)
            console.log(res)
            downloadStream(res, `老人登记表.xlsx`)
        },
        /*默认当前月份*/
        getNowTime() {
            let now = new Date()
            let year = now.getFullYear() //得到年份
            let month = now.getMonth() //得到月份
            month = month + 1
            month = month.toString().padStart(2, '0')
            let defaultDate = `${year}-${month}`
            this.$set(this.queryParams, 'recordMonth', defaultDate)
        },
        resetHandler() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                examineGrade: '',
                keyWord: '',
                mzeeType: '',
                newStatus: '',
                recordMonth: '',
                serviceId: '',
                state: ''
            }
            this.getNowTime()
            this.getList()
        },
        async institutionsListData() {
            const res = await institutionsList()
            this.institutionsListConfig = res.data
        },
        handleClose() {
            this.dialogVisible = false
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        seeDetial(row) {
            this.$router.push({
                path: '/applyExamine/elderlyView',
                query: { id: row.id }
            })
        },
        toReview(row) {
            this.$router.push({
                path: '/applyExamine/elderlyToReview',
                query: { id: row.id }
            })
        },
        // 管理人员列表
        async getList() {
            this.loading = true
            try {
                const res = await registrationList(this.queryParams)
                this.tableData = res.data.rows
                this.total = res.data.total
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.queryParams.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.queryParams.pageSize = val
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
              margin-left: 55px !important;
              margin-right: 55px;
          }*/
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
